// extracted by mini-css-extract-plugin
export var LandingPitch = "index-module--LandingPitch--Ppl1f";
export var BuyNowLink = "index-module--BuyNowLink--2loh5";
export var CarouselLetter = "index-module--CarouselLetter--guU3H";
export var wheel = "index-module--wheel--2YYhG";
export var wheelItem = "index-module--wheelItem--nr7hT";
export var wheelSelected = "index-module--wheelSelected--1EGrC";
export var goToFeaturesWrapper = "index-module--goToFeaturesWrapper--JMlbf";
export var goToFeatures = "index-module--goToFeatures--2X9ai";
export var acronymCallout = "index-module--acronymCallout--3A5KN";
export var videoMore = "index-module--videoMore--4fc80";