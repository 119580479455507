import * as React from "react"
import PropTypes from "prop-types"
//import useWindowSize from "./UseWindowSize.js"
import * as styles from "./ImageCard.module.css"
import {GatsbyImage, getImage} from "gatsby-plugin-image"


const ImageCard = ({ image, headHighlight, headText, descText, left }) => {
    //const {width} = useWindowSize();
    //const gradStyleBase = {gridArea: "1/1/2/3", zIndex: "1"};
    //const leftGrad = {background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(9,9,121,0) 74%, rgba(0,212,255,0) 100%)"};
    //const rightGrad = {background: "linear-gradient(90deg, rgba(0,212,255,0) 0%, rgba(9,9,121,0) 36%, rgba(0,0,0,1) 100%"};
    //const gradStyle = {...gradStyleBase, ...(left ? leftGrad :rightGrad)};


    return (
    <div className={styles.wrapper} style={{display: "grid", maxWidth: "1200px"}}>
        <div className={`${styles.textContent}`} style={{gridArea: "1/1/2/2"}}>
            <div className={styles.textWrapper}>
                <span className={styles.headHighlight}>{headHighlight}</span>
                <span className={styles.headText}>{headText}</span>
                <p>{descText}</p>
            </div>
            
        </div>
        <div className={styles.imageWrapper} >
        <GatsbyImage 
            image={getImage(image)}
            alt=""
            objectFit="contain"
            style={{height: "100%", width: "100%"}}
        />
        </div>
        {/*width > 768 && (
            <div style={gradStyle}>
            </div>
        )*/}
        
    </div>
    )
}

ImageCard.propTypes = {
    headHighlight: PropTypes.string,
    headText: PropTypes.string,
    descText: PropTypes.string,
    left: PropTypes.bool
  }
  
export default ImageCard
