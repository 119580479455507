import * as React from "react"
import { Link, graphql } from "gatsby"
//import { getImage, StaticImage } from "gatsby-plugin-image"
import * as styles from "./index.module.css"
import { MdArrowForward} from "react-icons/md/";
import {BsChevronCompactDown} from "react-icons/bs"
import { IconContext } from "react-icons";
//import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import ImageCard from "../components/ImageCard.js"
import Footer from "../components/footer"
import IndexWhatIs from "../components/indexWhatIs"
//import Acronym from "../components/acronym"
import overlayStill from "../devimages/OverlayStill.png"
import overlayWebm from "../devimages/Overlay_Whitewiresolid_Raw-1.webm"
import overlayMp4 from "../devimages/overlay_whitewiresolid8vbr.mp4"

const IndexPage = ({data}) => {
  const videoMoreScroll= () =>{
    window.scroll(0,window.innerHeight)
  }
  return(
  <div style={{backgroundColor: "rgb(40, 40, 40)", fontFamily: "'Inter' sans-serif", fontSize: "14px", lineHeight: "20px"}}>
    
    <Seo  />

      <Header siteTitle={`CRE3D Controller`} style={{
        position: "absolute",
        zIndex: "1",
        background: "linear-gradient(180deg, black 0%, rgba(0,0,0,0) 100%)"
        }}/>
    <div style={{height:"100vh", display: "grid"}}>
    
      {/*<StaticImage
          src="../images/cre3d_top_view.jpg"
          layout="fullWidth"
          formats={["AUTO", "WEBP", "AVIF"]}
          aspectRatio={2.5/1}
          alt=""
          style={{ gridArea: "1/1" }}
      /> */}
      <div style={{width: "100%", height: "100vh", gridArea: "1/1", overflow: "hidden", display: "flex", justifyContent: "Center"}}>
      <video autoPlay={true}
        autoplay=""
        loop={true}
        poster={overlayStill}
        preload="metadata"
        playsInline={true}
        playsinline=""
        webkit-playsinline=""
        muted={true}
        disablePictureInPicture
        style={{height: "100%", width: "100vw", objectFit: "cover"}}>
        
        <source src={overlayWebm} type="video/webm" track=""/>
        <source src={overlayMp4} type="video/mp4" track="" />
        <p style={{
            color: "white",
            fontSize: "2em"
        }}>Video not supported by browser</p>
      </video>
      </div>
      <div style={{gridArea:"1/1", position:"relative", background: "radial-gradient(rgba(3,3,3,40%), rgba(0,0,0,30%))", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <h1 className={styles.LandingPitch} style={{color: "white", width:"80%", margin: "0"}}>A Revolutionary Approach To Video Game Controllers</h1>
        <IconContext.Provider value={{ style: { verticalAlign: 'middle', height: "100%", marginLeft: ".3em"}, size: "1.25em"} }>
        <Link to="/buy/" className={styles.BuyNowLink}><span style={{fontSize: "1em", lineHeight: "1.5em"}}>Buy Now</span><MdArrowForward /></Link>
        </IconContext.Provider>
      </div>
      <div className={styles.videoMore} onClick={videoMoreScroll}>
      <IconContext.Provider value={{size: "50px"}}>
        <BsChevronCompactDown />
      </IconContext.Provider>
      </div>
    </div>
    {/* C = Customizable */}
    {/*<Acronym />*/}
    
    <div style={{width: "100%", background: "linear-gradient(90deg, rgba(10,10,10,1) 0%, rgba(0,0,0,0) 80%, rgba(255,255,255,0) 100%)"}} >
    <IndexWhatIs 
      images={[
      { src: data.top_3_jpg.gatsbyImageData, alt: "Main Photo of Controller" },
      //{ src: data.cre3d_side_view.gatsbyImageData, alt: "Side View of Controller" },
      { src: data.keys_1_jpg.gatsbyImageData, alt: "Secondary Side View of Controller" },
      { src: data.usb_1_jpg.gatsbyImageData, alt: "View of Controller Electronics" },
      { src: data.back_1_jpg.gatsbyImageData, alt: ""},
      {src: data.granite_1_jpg.gatsbyImageData, alt: ""},
      {src: data.mcu_removed_img.gatsbyImageData, alt: ""},
      {src: data.assembly_1_jpg.gatsbyImageData, alt: ""}
  
  ]}/>
    <div className={styles.acronymCallout}>
      <h2>What does <span>CRE3D</span> stand for?</h2>
    </div>
    <ImageCard image={data.gui_img.gatsbyImageData} 
                headHighlight="C"
                headText="ustomizable"
                descText="Every input is fully programmable. Use the PC Application to create custom layouts for each game. Once uploaded to the controller, swap between layouts on-the-fly via special button sequences."
                left={true} />

    <ImageCard image={data.mcu_removed_img.gatsbyImageData} 
                headHighlight="R"
                headText="epairable"
                descText="Analog stick drift is unavoidable. The modular electronics design allows for easy repairs and cheaper replacement parts. 
                Stock up on replacement analog sticks so you never have to buy a new controller again."
                left={true} />
    
    <ImageCard image={data.ergonomic_img.gatsbyImageData}
                headHighlight="E"
                headText="rgonomic"
                descText={"\"Claw\" grip used by competitive players is known to cause carpal tunnel and arthritis issues long term. With the CRE3D controller you can access 14 buttons without moving your thumbs off the analog sticks. That's the equivalent of every button on a standard controller, sans \"start\" and \"back/pause\""}
                left={true}/>
    {/* FazeFlea https://www.youtube.com/watch?v=kZ8y6dVCN3E
        PlayStationGrenade https://www.youtube.com/watch?v=hWC-Ow8hqIs
        TygeRCOD https://www.youtube.com/watch?v=TKHS8oEhawc
        Nickmercs https://www.youtube.com/watch?v=2cyf2Ap9nRI
        EsportsHealthCare https://esportshealthcare.com/claw-grip-for-controller/
    */}
    <ImageCard image={data.prototypes_img.gatsbyImageData}
                headHighlight="3D"
                headText=" Printed"
                descText={"3D printed in PLA. 3D printing allows for rapid iteration and testing of ideas. It also enables small scale production without the high up-front costs of traditional plastic manufacturing methods."}
                left={true}
                />

  <div className={styles.goToFeaturesWrapper}>
      <Link to="/overview" className={styles.goToFeatures}>
        <span>Learn About The Full Capabilities</span>
        <IconContext.Provider value={{style: {marginLeft: ".25em"}, size: "1.5em"}}>
          <MdArrowForward />
        </IconContext.Provider>
      
      </Link>
      {/*<span>Check out the <Link to="/features/">Features Page</Link> for more details</span>*/}
    </div>
    </div>
    {/*<div className={styles.wheel}>
      <span className={styles.wheelItem}>Customized</span>
      <span className={`${styles.wheelItem} ${styles.wheelSelected}`}>Repairable</span>
      <span className={styles.wheelItem}>Ergonomic</span>
      <span className={styles.wheelItem}>3D Printed</span>
    </div> */}
    
    
    <Footer />
  </div>
)}



export const query = graphql`
  query MyQuery{
  cre3dtop: imageSharp(resize: {originalName: {eq: "cre3d_electronics_top.jpg"}}) {
    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, JPG, WEBP, AVIF])
  }
  gui_img: imageSharp(resize: {originalName: {eq: "gui_labeled.png"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
  ergonomic_img: imageSharp(resize: {originalName: {eq: "claw-grip.png"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
  prototypes_img: imageSharp(resize: {originalName: {eq: "prototypes_wm_2.jpg"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
  mcu_removed_img: imageSharp(resize: {originalName: {eq: "mcu_removed.jpg"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }

  top_3_jpg: imageSharp(resize: {originalName: {eq: "top_3.jpg"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
  keys_1_jpg: imageSharp(resize: {originalName: {eq: "keys_1.jpg"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
  usb_1_jpg: imageSharp(resize: {originalName: {eq: "usb_1.jpg"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
  back_1_jpg: imageSharp(resize: {originalName: {eq: "back_1.jpg"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
  granite_1_jpg: imageSharp(resize: {originalName: {eq: "granite_1.jpg"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
  assembly_1_jpg: imageSharp(resize: {originalName: {eq: "assembly_1.jpg"}}) {
    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
  }
}
`

export default IndexPage