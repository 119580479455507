import React, {useState, useRef, useEffect} from "react"
import * as styles from "./indexWhatIs.module.css"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {IconContext} from "react-icons"
import {VscCircleFilled, VscCircleOutline} from "react-icons/vsc"

const IndexWhatIs = ({images}) => {
    const [imageIdx, setImageIdx] = useState(images.length > 0 ? 0 : -1)
    const imgRef = useRef(null)
    const getMainImage = () => {
        return images.length > 0 ? images[imageIdx] : null;
    }
    useEffect(() => {
        const nextImg = setTimeout(() => {
            setImageIdx((pIdx) => ((pIdx + 1) % images.length))
            //imgRef.current.style.opacity="100%"
          }, 8000);
        const fadeout = setTimeout(() => {
          imgRef.current.style.opacity="0"
        }, 6500);
        return () => {
            clearTimeout(nextImg)
            clearTimeout(fadeout);
            if (imgRef && imgRef.current) {
                imgRef.current.style.opacity="100%"
            }
            
        }
      }, [imageIdx]);
    return (
    <div className={styles.whatIsWrapper}>
        <div className={styles.whatIsText}>
            <h2 className={styles.whatIsTitle}>
                {/*What is the <span style={{fontFamily: "aAbrigyThink"}}>CRE3D</span> controller?*/}
                What is it?
            </h2>
            <div className={styles.whatIsDesc}>
                <p>
                    The CRE3D controller is an ergonomics focused, 3D printed video game controller featuring:
                </p> 
                <ul>
                    <li>12 mechanical switches (18 buttons total)</li>
                    <li>Fully programmable buttons and customizable analog stick response curves</li>
                    <li>Best-in-class latency featuring 1000hz polling and report rate</li>
                    <li>Consumer-first design for easy repairs and longevity</li>
                    <li>Compatible with Windows 8+ and most popular games including Call of Duty, Fortnite, Apex Legends, Halo and more</li>
                </ul>
                    {/*- 18 fully programmable buttons
                    - customizable analog response curves
                    - best-in-class latency
                    -designed for repair rather than planned obsolecense.*/}
                <p className={styles.descP2}>
                    No other controller on the market is comparable in features or performance, making the CRE3D controller the ultimate peripheral for competitive gamers.
                </p>
            </div>
        </div>
        <div className={styles.whatIsGallery}>
            <div className={styles.mainImageWrapper} ref={imgRef}>
            <GatsbyImage 
                image={getImage(getMainImage().src)}
                alt=""
                objectFit="contain"
                style={{width: "100%"}}
                className={styles.mainImage}
            />
            </div>
            <div className={styles.imgDotsContainer}>
            <IconContext.Provider value={{ style: {verticalAlign: "middle"}, className: styles.imgDots, size: "1em"}}>
                {images.map( (img, idx) => {
                    return (
                        <div onClick={()=>setImageIdx(idx)} onKeyPress={()=>setImageIdx(idx)} style={{zIndex: "1"}}>
                            {idx === imageIdx ? 
                                <VscCircleFilled />
                                :
                                <VscCircleOutline />
                            }
                        </div>
                    )
                })}
            </IconContext.Provider>
            </div>
        </div>
    </div>
)}

export default IndexWhatIs;